import { ArtiEnvironment } from './model';

export const environment: ArtiEnvironment = {
  dynamicSettings: false,
  production: false,
  debug: 'ArtifactRepository:*',
  apiUrl: 'https://api.dev.sickag.cloud/artifact-repository/',

  oidc: {
    // Url of the Identity Provider
    issuer: 'https://id.test.sick.com/auth/realms/sickservices',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'artifact-repository',
  },
  oidcPassChange: 'https://id.test.sick.com/auth/realms/sickservices/account/password',
  oidcProfile: 'https://id.test.sick.com/auth/realms/sickservices/account/',
  groupManagement: {
    baseUrl: 'https://groups.test-cloud.sick.com',
    apiUrl: 'https://groups.test-cloud.sick.com/api/',
    adminGroupId: '407d1433-859a-4523-9eff-b0a8c5fea01f',
  },

  featureToggles: {},
};
